import React, { useState, useRef, useEffect } from 'react';
import { MainDiv, SliderValue, Path, Knob } from './Slider.styled';
import SliderKnob from '../../Assets/Images/SliderKnob.png';
import SliderPath from '../../Assets/Images/SliderPath.png';

const Slider = ({ maxValue = 10000, minValue = 0, initialValue = 0, unit = 'dollar', unitStep = 100, mobMargin,mobHeight, onChange }) => {
    const [sliderValue, setSliderValue] = useState(initialValue);
    const [pathTopOffset, setPathTopOffset] = useState(0);
    const [knobWidth, setKnobWidth] = useState(32); // Default knob width
    const [knobHeight, setKnobHeight] = useState(20); // Default knob height
    const sliderRef = useRef(null);

    useEffect(() => {
        // Update the initial state with the passed initial value
        setSliderValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        // Notify the parent component of value changes when `sliderValue` changes
        onChange && onChange(sliderValue);
    }, [sliderValue, onChange]);

    useEffect(() => {
        const updateKnobSize = () => {
            if (window.innerWidth < 768) {
                setKnobWidth(45);
                setKnobHeight(30);
            } else {
                setKnobWidth(32);
                setKnobHeight(20);
            }
        };
        updateKnobSize();
        window.addEventListener('resize', updateKnobSize);
        return () => window.removeEventListener('resize', updateKnobSize);
    }, []);

    useEffect(() => {
        if (sliderRef.current) {
            const sliderHeight = sliderRef.current.getBoundingClientRect().height;
            const calculatedOffset = (knobHeight - sliderHeight) / 2;
            setPathTopOffset(calculatedOffset);
        }
    }, [sliderRef, knobHeight]);

    const moveSlider = (clientX) => {
        if (sliderRef.current) {
            const sliderRect = sliderRef.current.getBoundingClientRect();
            const sliderWidth = sliderRect.width;
            let newLeft = clientX - sliderRect.left;
            if (newLeft < 0) newLeft = 0;
            if (newLeft > sliderWidth - knobWidth) newLeft = sliderWidth - knobWidth;

            const percentage = newLeft / (sliderWidth - knobWidth);
            let newSliderValue = (percentage * (maxValue - minValue)) + minValue; // Adjusted to account for `minValue`

            // Ensure the slider value adheres to the unitStep increments
            newSliderValue = Math.round(newSliderValue / unitStep) * unitStep;

            setSliderValue(newSliderValue);
        }
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        const onMouseMove = (moveEvent) => {
            moveSlider(moveEvent.clientX);
        };
        const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    };

    const handleTouchStart = (e) => {
        e.preventDefault();
        const onTouchMove = (touchEvent) => {
            moveSlider(touchEvent.touches[0].clientX);
        };
        const onTouchEnd = () => {
            window.removeEventListener('touchmove', onTouchMove);
            window.removeEventListener('touchend', onTouchEnd);
        };
        window.addEventListener('touchmove', onTouchMove, { passive: false });
        window.addEventListener('touchend', onTouchEnd);
    };

    const knobPosition = ((sliderValue - minValue) / (maxValue - minValue)) * (sliderRef.current?.getBoundingClientRect().width - knobWidth || 442 - knobWidth);

    const formatValue = () => {
        const roundedValue = Math.round(sliderValue);
        switch (unit) {
            case 'dollar':
                return `$${roundedValue.toLocaleString()}`;
            case 'percentage':
                return `${((sliderValue / maxValue) * 100).toFixed(0)}%`;
            case 'hours':
                return `${roundedValue.toLocaleString()} hours`;
            case 'weeks':
                return `${roundedValue.toLocaleString()} weeks`;
            case 'months':
                return `${Math.round(roundedValue / 30).toLocaleString()} months`;
            default:
                return roundedValue.toLocaleString();
        }
    };

    return (
        <MainDiv mobHeight={mobHeight} mobMargin={mobMargin}>
            <Path
                src={SliderPath}
                alt="Slider Path"
                ref={sliderRef}
                style={{ top: `${pathTopOffset}px` }}
            />
            <Knob
                src={SliderKnob}
                alt="Knob"
                style={{
                    position: 'absolute',
                    left: `${knobPosition}px`,
                    width: `${knobWidth}px`,
                    height: `${knobHeight}px`,
                }}
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}
            />
            <SliderValue>
                {formatValue()}
            </SliderValue>
        </MainDiv>
    );
};

export default Slider;
