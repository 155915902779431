import React, { useState, useRef, useEffect } from "react";
import Dropdown from '../Assets/Images/Dropdown.png';
import CrownImg from '../Assets/Images/CrownImg.png';
import OpenIcon from '../Assets/Images/OpenIcon.png';
import CloseIcon from '../Assets/Images/CloseIcon.png';
import {
    RateHeading, RateDiv, ContainDiv, QuesDesc, TooltipImg, SliderHead, AdvSetting, AdvSliderHead, CrownDiv, CrownHeading, Heading, CrownMain, CrownFooter, CrownStyled, FooterRates, Per, Hr, ToggleButton, ToggleContainer, MobileCrownDiv, PerHrRate, USD, PerHr, RateContainer, DropArrow
} from "./RateFinder.styled";
import Slider from "../Components/Slider/Slider";
import Footer from "../Components/Footer/Footer";
import Tooltip from '../Assets/Images/Tooltip.png';
const RateFinder = () => {
    const defaultValues = {
        annualIncome: 5000,
        hoursPerDay: 1,
        weeksOff: 0,
        buffer: 0,
        estimatedExpenses: 0,
        otherIncome: 0,
    };
    const [annualIncome, setAnnualIncome] = useState(defaultValues.annualIncome);
    const [hoursPerDay, setHoursPerDay] = useState(defaultValues.hoursPerDay);
    const [weeksOff, setWeeksOff] = useState(defaultValues.weeksOff);
    const [buffer, setBuffer] = useState(defaultValues.buffer);
    const [estimatedExpenses, setEstimatedExpenses] = useState(defaultValues.estimatedExpenses);
    const [otherIncome, setOtherIncome] = useState(defaultValues.otherIncome);

    const [calculatedRates, setCalculatedRates] = useState({});
    const [dialAngle, setDialAngle] = useState(-121);

    const crownRef = useRef(null);
    const mobileCrownRef = useRef(null);
    const [showCrownDiv, setShowCrownDiv] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [tooltipState, setTooltipState] = useState({
        salary: false,
        hours: false,
        buffer: false,
        expenses: false,
        income: false,
    });
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const toggleCrownDiv = () => {
        setShowCrownDiv(!showCrownDiv);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleTooltip = (key) => {
        setTooltipState(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };
    const calculateRates = () => {
        const totalWeeksPerYear = 52 - weeksOff;
        const totalAnnualWorkingHours = hoursPerDay * 5 * totalWeeksPerYear;

        // If estimatedExpenses (Question 5) is 0, set requiredIncome to annualIncome only
        let effectiveOtherIncome = estimatedExpenses === 0 ? 0 : otherIncome;

        // Calculate the income required after accounting for expenses and other income
        let requiredIncome = Math.max(0, annualIncome + estimatedExpenses - effectiveOtherIncome);

        // Calculate buffer as a percentage increase to the required income
        const bufferFactor = 1 + buffer / 100;

        // Calculate hourly, daily, weekly, and monthly rates
        const hourlyRate = totalAnnualWorkingHours ? (requiredIncome * bufferFactor / totalAnnualWorkingHours) : 0;
        const dailyRate = hourlyRate * hoursPerDay;
        const weeklyRate = dailyRate * 5;
        const monthlyRate = weeklyRate * 4.34524;
        console.log(hoursPerDay,hourlyRate,dailyRate,weeklyRate,monthlyRate)

        return { hourlyRate, dailyRate, weeklyRate, monthlyRate };
    };
    useEffect(() => {
        const rates = calculateRates();
        setCalculatedRates(rates);

        const minAngle = -121;
        const maxAngle = 94;
        const maxRate = 1000;

        // Calculate new angle based on hourly rate and clamp it between min and max angles
        let newAngle = ((rates.hourlyRate / maxRate) * (maxAngle - minAngle)) + minAngle;

        // Clamp the angle to ensure it stays within the limits
        newAngle = Math.min(Math.max(newAngle, minAngle), maxAngle);
        setDialAngle(newAngle);
    }, [annualIncome, hoursPerDay, weeksOff, buffer, estimatedExpenses, otherIncome]);

    useEffect(() => {
        const handleResize = () => {
            if (isInitialLoad && window.innerWidth < 768) {
                setShowCrownDiv(true);
                const timer = setTimeout(() => {
                    setShowCrownDiv(false);
                    setIsInitialLoad(false);
                }, 2000);
                return () => clearTimeout(timer);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isInitialLoad]);

    useEffect(() => {
        if (showCrownDiv) {
            mobileCrownRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            });
        }
    }, [showCrownDiv]);
    return (
        <>
            <ContainDiv>
                <RateDiv>
                    <RateHeading>Want to find your rate?</RateHeading>
                    <QuesDesc margin='24px 0 48px 0' mobMargin='14px 0 25px 0'>
                        If you freelance part or full-time, this app makes it<br /> easy to find your ideal rate.
                    </QuesDesc>
                    <QuesDesc margin='0 0 8px 0'>
                        How much do you want to make freelancing in a year?
                        <TooltipImg src={Tooltip} onClick={() => toggleTooltip('salary')} />
                    </QuesDesc>
                    {tooltipState.salary && (
                        <SliderHead margin='0 0 24px 0' mobMargin='0 0 20px 0'>
                            Adjust to match how much money you’d like to make in a year
                        </SliderHead>
                    )}
                    <Slider maxValue={250000} minValue={5000} unitStep={5000} initialValue={annualIncome} unit="dollar" onChange={setAnnualIncome} />
                    <QuesDesc margin='60px 0 8px 0' mobMargin='45px 0 8px 0'>
                        How many hours per day will you freelance on average?
                        <TooltipImg src={Tooltip} onClick={() => toggleTooltip('hours')} />
                    </QuesDesc>
                    {tooltipState.hours && (
                        <SliderHead margin='0 0 24px 0' mobMargin='0 0 20px 0'>
                            To figure out the average, add up the amount of hours you<br /> might freelance over the entire week then divide by 5 days.
                        </SliderHead>
                    )}
                    <Slider maxValue={8} minValue={1} unitStep={1} initialValue={hoursPerDay} unit="hours" onChange={setHoursPerDay} />
                    <QuesDesc margin='60px 0 24px 0' mobMargin='45px 0 20px 0'>
                        About how many weeks will you take off in a year?
                    </QuesDesc>
                    <Slider maxValue={16} unitStep={1} initialValue={weeksOff} unit="weeks" onChange={setWeeksOff} />
                    <QuesDesc margin='60px 0 8px 0' mobMargin='45px 0 8px 0'>
                        Want to add some buffer to your rate?
                        <TooltipImg src={Tooltip} onClick={() => toggleTooltip('buffer')} />
                    </QuesDesc>
                    {tooltipState.buffer && (
                        <SliderHead margin='0 0 24px 0' mobMargin='0 0 20px 0'>
                            This can be helpful if you want room to negotiate your rate a<br /> little or cover additional potential expenses.
                        </SliderHead>
                    )}
                    <Slider maxValue={100} unitStep={1} initialValue={buffer} unit="percentage" onChange={setBuffer} />
                    <AdvSetting margin='60px 0 4px 0' mobMargin='45px 0 4px 0' onClick={toggleDropdown}>
                        Advanced settings (optional)
                        <DropArrow src={Dropdown} style={{ cursor: 'pointer', transform: isDropdownOpen ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s ease' }} />
                    </AdvSetting>
                    <div
                        style={{
                            maxHeight: isDropdownOpen ? '1000px' : '0px',
                            overflow: 'hidden',
                            transition: 'max-height 0.8s ease-in-out',
                        }}
                    >
                        <AdvSliderHead margin='0 0 24px 0' mobMargin='0 0 14px 0'>
                            Find your rate for covering your expenses.
                        </AdvSliderHead>
                        <QuesDesc margin='24px 0 8px 0' mobMargin='15px 0 4px 0'>
                            What are your estimated expenses in a year?
                            <TooltipImg src={Tooltip} onClick={() => toggleTooltip('expenses')} />
                        </QuesDesc>
                        {tooltipState.expenses && (
                            <SliderHead margin='0 0 24px 0' mobMargin='0 0 20px 0'>
                                Get a rough estimate of all your major expenses, including<br /> rent/mortgage, car payments, subscriptions, groceries,<br /> phone, internet, etc. It doesn’t have to be exact, but the more<br /> accurate the better.
                            </SliderHead>
                        )}
                        <Slider maxValue={250000} unitStep={1000} initialValue={estimatedExpenses} unit="dollar" onChange={setEstimatedExpenses} />
                        <QuesDesc margin='60px 0 8px 0' mobMargin='45px 0 8px 0'>
                            How much other annual income do you have?
                            <TooltipImg src={Tooltip} onClick={() => toggleTooltip('income')} />
                        </QuesDesc>
                        {tooltipState.income && (
                            <SliderHead margin='0 0 24px 0' mobMargin='0 0 20px 0'>
                                If you have another job or other types of income, add them<br /> together for a total amount.
                            </SliderHead>
                        )}
                        <Slider mobHeight='30px' maxValue={250000} unitStep={1000} initialValue={otherIncome} unit="dollar" onChange={setOtherIncome} />
                    </div>
                </RateDiv>
                <ToggleContainer>
                    <ToggleButton onClick={toggleCrownDiv}>
                        <img src={showCrownDiv ? CloseIcon : OpenIcon} alt="Toggle" />
                    </ToggleButton>
                </ToggleContainer>
                <CrownDiv>
                    <CrownHeading>
                        <Heading>You’ll reach your goal by charging around...</Heading>
                    </CrownHeading>
                    <CrownMain>
                        <CrownStyled src={CrownImg} dialAngle={dialAngle} />
                        <PerHrRate>
                            <RateContainer>
                                <USD>$&nbsp;</USD>{Math.round(calculatedRates.hourlyRate || 0).toLocaleString()}
                            </RateContainer>
                            <PerHr>Per Hour</PerHr>
                        </PerHrRate>

                    </CrownMain>
                    <CrownFooter>
                        <div style={{ textAlign: 'center' }}>
                            <FooterRates>${Math.round(calculatedRates.dailyRate || 0).toLocaleString()}</FooterRates>
                            <Per>Per Day</Per>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <FooterRates>${Math.round(calculatedRates.weeklyRate || 0).toLocaleString()}</FooterRates>
                            <Per>Per Week</Per>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <FooterRates>${Math.round(calculatedRates.monthlyRate || 0).toLocaleString()}</FooterRates>
                            <Per>Per Month</Per>
                        </div>
                    </CrownFooter>
                </CrownDiv>

                <MobileCrownDiv ref={mobileCrownRef} isVisible={showCrownDiv}>
                    <CrownHeading>
                        <Heading>You’ll reach your goal by charging around...</Heading>
                    </CrownHeading>
                    <CrownMain>
                        <CrownStyled src={CrownImg} dialAngle={dialAngle} />
                        <PerHrRate>
                            <RateContainer>
                                <USD>$&nbsp;</USD>{Math.round(calculatedRates.hourlyRate || 0).toLocaleString()}
                            </RateContainer>
                            <PerHr>Per Hour</PerHr>
                        </PerHrRate>

                    </CrownMain>
                    <CrownFooter>
                        <div style={{ textAlign: 'center' }}>
                            <FooterRates>${Math.round(calculatedRates.dailyRate || 0).toLocaleString()}</FooterRates>
                            <Per>Per Day</Per>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <FooterRates>${Math.round(calculatedRates.weeklyRate || 0).toLocaleString()}</FooterRates>
                            <Per>Per Week</Per>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <FooterRates>${Math.round(calculatedRates.monthlyRate || 0).toLocaleString()}</FooterRates>
                            <Per>Per Month</Per>
                        </div>
                    </CrownFooter>
                </MobileCrownDiv>

            </ContainDiv>
            <Hr />
            <Footer />
        </>
    )
}

export default RateFinder;