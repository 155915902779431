import styled from "styled-components";

export const SliderValue = styled.div`
color: #668AB7;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
position: absolute;
right: 0px;
top: 50%;
transform: translateY(-50%);
`
export const MainDiv = styled.div`
position: relative;
width: 520px;
height: 20px;
@media (max-width: 768px) {
  width: 100%;
  margin: ${({ mobMargin }) => mobMargin || ""};
  height: ${({ mobHeight }) => mobHeight || ""};
}`;
export const Path = styled.img`
 position: absolute;
  width: 442px;
  height: 10px;
  @media (max-width: 768px) {
      width: 82%;
  }
`
export const Knob = styled.img`
 position: absolute;
  width: 32px;
  height: 20px;
  cursor: pointer;
  top:0;
  
`