import React from "react";
import user from '../../Assets/Images/user.png'
import { FooterMain, UserDiv, UserImg, FooterText, Link, MadeByText,Br } from "./Footer.styled";
const Footer = () => {
    return (
        <>
            <FooterMain>
                <UserDiv>
                    <UserImg src={user} />
                    <FooterText>
                        Found this useful?<br />
                        <Link href="https://ko-fi.com/timhaskins" target="_blank">Buy me a coffee</Link> to support this site.
                    </FooterText>
                </UserDiv>
                <MadeByText>
                    <i>
                        Rates provided on this site should not be considered professional<Br /> financial advice.
                    </i><br />
                    Made by <Link href="https://timhaskins.studio?referer=fmr" target="_blank">Tim Haskins Studio</Link>
                </MadeByText>
            </FooterMain>
        </>
    )
}
export default Footer;