import styled, { keyframes } from "styled-components";
import DialBg from '../Assets/Images/DialBg.png'

const rotate = keyframes`
  0% {
    transform: rotate(-121deg); /* Start from the minimum angle */
  }
  50% {
    transform: rotate(94deg); /* Rotate to the maximum angle */
  }
  100% {
    transform: rotate(-121deg); /* Back to the minimum angle */
  }
`;
export const ContainDiv = styled.div`
  display: flex;
  max-width: 1240px;
  margin-inline: auto;
  padding: 56px 100px;
  @media (max-width: 768px) {
    display:block;
    padding: 16px;
    width: 90%;}`;
export const RateDiv = styled.div`
  width: 692px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;}`;
export const ToggleContainer = styled.div`
  position: fixed;
  right: 0px;
  top: 121px;
  z-index: 10;
  @media (min-width: 768px) {
    display: none;}`;
export const ToggleButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  img {
    width: 76px;
    height: 107px;}`;
export const RateHeading = styled.h2`
  color: #f7f7f7;
  font-size: 44px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.44px;
  margin: 0;
   @media (max-width: 768px) {
    font-size: 35px;}`;
export const QuesDesc = styled.h4`
display:flex;
  align-items: center;
  color: #909cab;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  margin: ${({ margin }) => margin || ""};
  @media (max-width: 768px) {
    font-size: 14px;
      line-height: 22px;
      margin: ${({ mobMargin }) => mobMargin || ""};
      }`;
export const TooltipImg = styled.img`
  margin-left: 8px;
  vertical-align: middle;
  cursor:pointer;`;
export const DropArrow = styled(TooltipImg)`
width:22px;
height:22px;  
  margin-left: 5px;
`;
export const SliderHead = styled.div`
margin: ${({ margin }) => margin || ""};
padding: 12px 16px;
border-radius: 8px;
background: #14171f;
color: #909cab;
font-size: 14px;
font-weight: 400;
width: 442px;
line-height: 21px; /* 150% */
 @media (max-width: 768px) {
 padding: 8px 10px;
    font-size: 12px;
      line-height: 18px;
      width: 96%;
      margin: ${({ mobMargin }) => mobMargin || ""};}`;
export const AdvSetting = styled(QuesDesc)`
font-weight: 600;
font-size: 15px;
line-height:0`;
export const AdvSliderHead = styled(SliderHead)`
background: transparent;
padding:0;`;
export const CrownDiv = styled.div`
padding: 0;
display: flex;
flex-direction: column;
align-items: center;
gap: 380px;
position: relative;
margin-top: 189px;
 @media (max-width: 768px) {
    display: none; 
  }`;
export const MobileCrownDiv = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 97%;
  background: #181B24;
  z-index: 9;
  padding: 12px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 260px;
   transition: transform 0.9s ease-in-out;  // Add transition for smooth slide
    transform: ${({ isVisible }) => (isVisible ? 'translateX(0)' : 'translateX(100%)')}
`;

export const CrownHeading = styled.div`
border-radius: 64px;
background: #181B24;
box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25) inset;
width: 436px;
height: 135px;
padding: 52px 32px;
 @media (max-width: 768px) {
width: 80%;
height: 176.105px;
padding: 38.316px 23.579px;
border-radius: 47.158px;
box-shadow: 0px 5.895px 5.895px 0px rgba(0, 0, 0, 0.25) inset;
  }`
export const Heading = styled.p`
color: #909CAB;
text-align: center;
font-size: 16px;
font-weight: 500;
line-height: 26px;
 @media (max-width: 768px) {
font-size: 11.789px;
line-height: 19.158px;
  }`
export const CrownMain = styled.div`
position:absolute;
width: 551px;
height: 551px;
border-radius: 551px;
opacity: 0.8;
top:165px;
background: rgba(0, 0, 0, 0.02);
box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25) inset;
background: url(${DialBg});
background-repeat: no-repeat;
 @media (max-width: 768px) {
width:406px;
height: 406px;
  background-position: center;
   background-size: contain
  }

`
export const CrownFooter = styled.div`
border-radius: 64px;
background: #181B24;
box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
width: 436px;
height: 115px;
padding: 124px 32px 0px 32px;
display:flex;
gap:60px;
  justify-content: center;
@media (max-width: 768px) {
width: 80%;
padding: 91.368px 23.579px 0px 23.579px;
border-radius: 47.158px;
box-shadow: 0px 5.895px 5.895px 0px rgba(0, 0, 0, 0.25);
  }
`
export const CrownStyled = styled.img`
  width: 551px;
  height: 552px;
  transform-origin: center center;
  transform: ${({ dialAngle }) => `rotate(${dialAngle}deg)`}; /* Apply dynamic rotation */
  transition: transform 0.5s ease-in-out; /* Smooth transition for the rotation */
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;
export const PerHr = styled.p`
  margin: 0;
  color: rgba(233, 233, 234, 0.70);
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 768px) {
 font-size: 11.789px;
}
`;
export const USD = styled.span` /* Changed from p to span for inline behavior */
  margin: 0;
  color: #E9E9EA;
  font-size: 28px;
  font-weight: 600;
    @media (max-width: 768px) {
font-size: 20.632px;
}
`;
export const PerHrRate = styled.h2`
  margin: 0 ;
width:100%;
  position: absolute;
  text-align: center;
  font-size: 52px;
  font-weight: 700;
  color: rgba(233, 233, 234, 0.90);
  white-space: nowrap;
  top:41%;
    @media (max-width: 768px) {
    font-size: 38.316px;
    top:41%;
}
`;
export const RateContainer = styled.div`
  display: inline-flex; /* Use flexbox to align $ and 221 on the same line */
  align-items: center; /* Aligns the baseline of both items */
  margin-inline:auto;
`;
export const FooterRates = styled.h2`
color: #909CAB;
font-size: 20px;
font-weight: 600;
line-height: normal;
margin:0;
@media (max-width: 768px) {
font-size: 14.737px;
  }
`
export const Per = styled.p`
color: #668AB7;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
@media (max-width: 768px) {
font-size: 10.316px;
  }
`
export const Hr = styled.div`
opacity: 0.3;
background: #111317;
width: 1240px;
height: 4px;
margin:8px auto 36px auto; 
 @media (max-width: 768px) {
    display: none; 
  }
`
