import styled from "styled-components";

export const FooterMain = styled.div`
display:flex;
justify-content: space-between;
width: 1240px;
margin-inline: auto; 
padding:36px 0;
@media (max-width: 768px) {
    flex-direction: column;
    width:90%;
    padding:36px 16px;
  }
`
export const UserDiv = styled.div`
display:flex;
gap:20px;
align-items: center;
 @media (max-width: 768px) {
   flex-direction: column;
   width:100%;
  }
`
export const UserImg = styled.img`
width:45px;
height:45px;
border-radius:50%;
`
export const FooterText = styled.p`
color: #909CAB;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 169.231% */
text-align: start;
 @media (max-width: 768px) {
 text-align:center;
 font-size: 10px;
  }
`
export const Link = styled.a`
color: #3779E5;
text-underline-offset: 3px;
`
export const Br = styled.br`
 @media (min-width: 768px) {
display:none;
  }
`
export const MadeByText = styled(FooterText)`
text-align: end;
 @media (max-width: 768px) {
 text-align:center;
  }
`